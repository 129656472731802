<template>
    <div class="flex gap-4 m-8 mb-0 justify-between">
        <Skeletor v-if="!data" class="mb-4" />
        <div class="flex items-center gap-4 flex-wrap">
            <div v-for="(weekdata, index) in data" :key="index" class="text-xl font-bold tracking-widest cursor-pointer mb-4" :id="'kw-tab-' + weekdata.week">
                KW{{ weekdata.week }}
            </div>
        </div>
        <round-button v-if="edit_date" @click="emitter.emit('open-order-modal', { date: edit_date })" :icon="'fa-solid fa-plus'" class="basis-9" />
    </div>

    <Skeletor v-if="!data" height="60vh" />
    <template v-else>
        <week-content
            v-for="(weekdata, index) in data"
            :key="index"
            :id="'kw-content-' + weekdata.week"
            :weekdata="weekdata"
            @tab-changed="() => setEditDate()"
            @mounted="(tabs) => datetabs.push(tabs)"
        />
    </template>
</template>

<script setup>
import { ref, nextTick, onMounted, inject } from "vue";
import { Tabs } from "flowbite";
import { useTitleStore } from "@/store/title-store";

import RoundButton from "@/components/global/RoundButton.vue";
import WeekContent from "@/components/partials/orders/WeekContent.vue";

import dayjs from "dayjs";
import axios from "axios";

var isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);

let weektabs = null;
let datetabs = [];

const emitter = inject("emitter");

const data = ref(null);

const titleStore = useTitleStore();
titleStore.setTitle("Bestellungen");

onMounted(() => {
    const today = dayjs();
    let tabElements = [];

    axios.get("orders_by_week/" + today.year() + "/" + today.isoWeek() + "/2").then((response) => {
        data.value = response.data;

        nextTick(() => {
            data.value.forEach((date) => {
                tabElements.push({
                    id: date.week,
                    triggerEl: document.querySelector("#kw-tab-" + date.week),
                    targetEl: document.querySelector("#kw-content-" + date.week),
                });
            });

            const options = {
                activeClasses: "text-black border-b-4 border-purple",
                inactiveClasses: "text-gray-light",
                onShow: () => {
                    setEditDate();
                },
            };
            weektabs = new Tabs(tabElements, options);
            setEditDate();
        });
    });
});

const edit_date = ref(null);

const setEditDate = () => {
    if (weektabs == null || datetabs == null) return;

    const week = weektabs.getActiveTab().id;

    datetabs.forEach((datetab) => {
        if (dayjs(datetab.getActiveTab().id).isoWeek() == week) {
            edit_date.value = dayjs(datetab.getActiveTab().id);
        }
    });
};
</script>
