<template>
    <div
        id="customer-modal"
        tabindex="-1"
        aria-hidden="true"
        class="fixed top-0 left-0 right-0 z-50 hidden w-full py-16 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full"
    >
        <div v-if="customer" class="relative w-full h-full max-w-md md:h-auto">
            <!-- Modal content -->
            <div class="relative bg-white shadow">
                <button
                    type="button"
                    class="absolute top-3 right-2.5 text-gray bg-transparent hover:bg-gray-light hover:text-gray-dark text-sm p-1.5 ml-auto inline-flex items-center"
                    @click="emitter.emit('close-modal')"
                >
                    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                        ></path>
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button>
                <div class="px-6 py-6 lg:px-8">
                    <div class="mb-4">
                        <text-input
                            label="Name"
                            placeholder="Name"
                            v-model:input="customer.name"
                            inputType="text"
                            :error="errors.name ? errors.name[0] : ''"
                            @change="errors.name = ''"
                        />
                        <text-input
                            label="Adresse"
                            placeholder="Adresse"
                            v-model:input="customer.address"
                            inputType="text"
                            :error="errors.address ? errors.address[0] : ''"
                            @change="errors.address = ''"
                        />
                        <select-input
                            label="Tour"
                            placeholder="Tour"
                            v-model:input="customer.tour_id"
                            :error="errors.tour_id ? errors.tour_id[0] : ''"
                            @change="
                                () => {
                                    customer.tour = tours.find((tour) => tour.id === customer.tour_id);
                                    errors.tour_id = '';
                                }
                            "
                        >
                            <option v-for="(tour, index) in tours" :key="index" :value="tour.id">{{ tour.name }}</option>
                        </select-input>
                        <select-input
                            label="Standard-Zahlungsart"
                            v-model:input="customer.payment_method_id"
                            :error="errors.payment_method_id ? errors.payment_method_id[0] : ''"
                            @change="
                                () => {
                                    customer.payment_method = payment_methods.find((payment_method) => payment_method.id === customer.payment_method_id);
                                    errors.payment_method_id = '';
                                }
                            "
                        >
                            <option v-for="(payment_method, index) in payment_methods" :key="index" :value="payment_method.id">
                                {{ payment_method.name }}
                            </option>
                        </select-input>
                        <select-input
                            label="Standard-Zahlungsweise"
                            v-model:input="customer.payment_type_id"
                            :error="errors.payment_type_id ? errors.payment_type_id[0] : ''"
                            @change="
                                () => {
                                    customer.payment_type = payment_types.find((payment_type) => payment_type.id === customer.payment_type_id);
                                    errors.payment_type_id = '';
                                }
                            "
                        >
                            <option v-for="(payment_type, index) in payment_types" :key="index" :value="payment_type.id">
                                {{ payment_type.name }}
                            </option>
                        </select-input>
                        <text-input
                            label="Telefonnummer"
                            placeholder="Telefonnummer"
                            v-model:input="customer.phone"
                            inputType="tel"
                            :error="errors.phone ? errors.phone[0] : ''"
                            @change="errors.phone = ''"
                        />
                        <text-input
                            label="WhatsApp"
                            placeholder="WhatsApp"
                            v-model:input="customer.whatsapp"
                            inputType="tel"
                            :error="errors.whatsapp ? errors.whatsapp[0] : ''"
                            @change="errors.whatsapp = ''"
                        />
                        <textarea-input label="Hinweise" placeholder="Hinweise" v-model:input="customer.notes" :error="errors.notes ? errors.notes[0] : ''" />
                    </div>

                    <button
                        class="font-montserrat text-xs block w-full bg-purple hover:bg-black text-white rounded-sm py-3 transition-colors duration-300 uppercase tracking-widest font-bold"
                        type="submit"
                        @click="saveCustomer"
                    >
                        <font-awesome-icon icon="fa-solid fa-user-edit" />
                        &nbsp;Speichern
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, inject, onMounted, ref, toRefs } from "vue";
import { useToast } from "vue-toast-notification";

import SelectInput from "@/components/global/SelectInput.vue";
import TextInput from "@/components/global/TextInput.vue";
import TextareaInput from "@/components/global/TextareaInput.vue";
import axios from "axios";

const props = defineProps({ customer: Object });
const { customer } = toRefs(props);

const emitter = inject("emitter"); // Inject `emitter`

const errors = ref([]);
const tours = ref([]);
const payment_methods = ref([]);
const payment_types = ref([]);
const $toast = useToast();

onMounted(async () => {
    tours.value = (await axios.get("tours")).data;
    payment_methods.value = (await axios.get("payment_methods")).data;
    payment_types.value = (await axios.get("payment_types")).data;
});

const saveCustomer = async () => {
    errors.value = [];

    try {
        if (customer.value.id) {
            await axios.post("customer/" + customer.value.id + "?_method=PUT", customer.value);
        } else {
            await axios.post("customer", customer.value);
        }

        $toast.success("Kundendaten gespeichert!");
        emitter.emit("customer-saved", JSON.parse(JSON.stringify(customer.value)));
        emitter.emit("close-modal");
    } catch (error) {
        let error_string = "";
        if (error.response.data.error) {
            error_string = error.response.data.error;
        } else if (error.response.data.errors) {
            errors.value = error.response.data.errors;
            return;
        } else {
            error_string = error;
        }
        $toast.error("Fehler: " + error_string + "<br>Kundendaten nicht gespeichert!");
    }
};
</script>

<style></style>
