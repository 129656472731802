<template>
    <div class="flex justify-between items-center gap-8">
        <div class="uppercase text-xl font-bold tracking-widest">Menu</div>
        <Slide right :closeOnNavigation="false" :isOpen="isOpen" class="flex gap-4 text-white whitespace-nowrap" ref="mainMenu" @openMenu="isOpen = true">
            <menu-item v-for="(menuItem, index) in menuItems" :key="index" :menuItem="menuItem" @link-clicked="isOpen = false" />
        </Slide>
    </div>
</template>

<script setup>
import axios from "axios";
import { ref, onMounted } from "vue";
import { Slide } from "vue3-burger-menu";
import { useUserStore } from "@/store/user-store";
import MenuItem from "./MenuItem.vue";

const mainMenu = ref(null);
const isOpen = ref(false);
const menuItems = ref([]);
const userStore = useUserStore();

onMounted(async () => {
    let tours = null;
    let tours_children = [];
    let daytours_children = [];
    switch (userStore.$state.role_id) {
        case 1:
            tours = (await axios.get("tours")).data;

            tours.forEach((tour) => {
                tours_children.push({
                    text: tour.name,
                    path: "/customers/tour/" + tour.id,
                });
                daytours_children.push({
                    text: tour.name,
                    path: "/daytour/" + tour.id,
                });
            });

            menuItems.value = [
                {
                    text: "Übersicht",
                    path: "/dashboard",
                },
                {
                    text: "Kunden",
                    children: [
                        {
                            text: "Alle",
                            path: "/customers",
                        },
                        {
                            text: "Touren",
                            children: tours_children,
                        },
                    ],
                },
                {
                    text: "Wochenmenü",
                    path: "/weekmenu",
                },
                //// NOTE disabled because currently not needed
                // {
                //     text: "Bestellungen",
                //     path: "/orders",
                // },
                {
                    text: "Touren",
                    children: daytours_children,
                },
                {
                    text: "Gerichte-Auswertung",
                    path: "/analysis/meals",
                },
                {
                    text: "Ausloggen",
                    path: "/logout",
                },
            ];
            break;
        default:
            menuItems.value = [
                {
                    text: "Übersicht",
                    path: "/dashboard",
                },
            ];

            tours = (await axios.get("user")).data.user.tours;

            if (tours.length === 1) {
                menuItems.value.push({
                    text: "Meine Tour: " + tours[0].name,
                    path: "/daytour/" + tours[0].id,
                });
            } else if (tours.length > 1) {
                tours.forEach((tour) => {
                    daytours_children.push({
                        text: tour.name,
                        path: "/daytour/" + tour.id,
                    });
                });
                menuItems.value.push({
                    text: "Meine Touren",
                    children: daytours_children,
                });
            }

            menuItems.value.push({
                text: "Ausloggen",
                path: "/logout",
            });
            break;
    }
});
</script>
