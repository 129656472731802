<template>
    <Skeletor v-if="!customer" height="60vh" />
    <template v-else>
        <div id="customer-content-data">
            <customer-details-table :customer="customer" :show-tour="true" />
            <div class="mx-4 py-4" v-if="isAdmin">
                <button
                    class="font-montserrat text-xs block w-full bg-purple hover:bg-black text-white py-3 transition-colors duration-300 uppercase tracking-widest font-bold"
                    type="submit"
                    @click="emitter.emit('open-customer-modal', customer)"
                >
                    <font-awesome-icon icon="fa-solid fa-pencil-alt" />
                    &nbsp;Kunde bearbeiten
                </button>
            </div>
            <div class="mx-4 pb-4" v-if="isAdmin">
                <button
                    class="font-montserrat text-xs block w-full bg-red-600 hover:bg-black text-white py-3 transition-colors duration-300 uppercase tracking-widest font-bold"
                    type="submit"
                    @click="deleteCustomer(customer)"
                >
                    <font-awesome-icon icon="fa-solid fa-trash-alt" />
                    &nbsp;Kunde löschen
                </button>
            </div>
        </div>
    </template>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, inject } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useTitleStore } from "@/store/title-store";
import { useUserStore } from "@/store/user-store";
import { useToast } from "vue-toast-notification";

import CustomerDetailsTable from "@/components/partials/customers/CustomerDetailsTable.vue";

import axios from "axios";

const emitter = inject("emitter");

const userStore = useUserStore();

const route = useRoute();
const router = useRouter();
const titleStore = useTitleStore();

const customer = ref(null);
const orders = ref(null);

const $toast = useToast();

onMounted(() => {
    loadData();
});

const loadData = () => {
    axios.get("customer/" + route.params.id).then((response) => {
        const data = response.data;

        customer.value = data.customer;
        orders.value = data.customer.orders;
        titleStore.setTitle("Kunde - " + data.customer.name);
    });
};

const deleteCustomer = async () => {
    if (!confirm("Sind Sie sicher, dass sie diesen Kunden löschen möchten? Dies kann nicht rückgängig gemacht werden!")) return;

    try {
        await axios.delete("customer/" + customer.value.id);
        $toast.success("Kunde gelöscht.");
        router.back();
    } catch (error) {
        let error_string = "";
        if (error.response.data.message) {
            error_string = error.response.data.message;
        } else {
            error_string = error;
        }
        $toast.error(error_string);
    }
};

let isAdmin = false;
onBeforeMount(() => {
    isAdmin = userStore.$state.role_id === 1;
});
</script>
