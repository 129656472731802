<template>
    <tr class="h-20 bg-gray-lighter print:hidden">
        <td>
            <div class="p-4 flex flex-row">
                <div
                    v-if="daytour_order.status !== 'open'"
                    class="self-stretch basis-12 flex justify-center items-center shrink-0"
                    :class="daytour_order.status == 'done' ? 'text-green-500' : 'text-red-600'"
                >
                    <font-awesome-icon :icon="daytour_order.status == 'done' ? 'fa-solid fa-check' : 'fa-solid fa-xmark'" />
                </div>
                <div v-else-if="sortable" class="handle self-stretch basis-12 flex justify-center items-center shrink-0">
                    <font-awesome-icon icon="fa-solid fa-grip" />
                </div>
                <div v-else class="self-stretch basis-12 flex justify-center items-center shrink-0">&nbsp;</div>
                <div>
                    <div>{{ daytour_order.order.customer.name }}</div>
                    <div>{{ daytour_order.order.customer.address }}</div>
                    <div class="flex text-sm" v-if="daytour_order.order.customer.notes">
                        <div>
                            <font-awesome-icon icon="fa-solid fa-circle-info" class="mr-2" />
                        </div>
                        <div class="whitespace-pre-line">
                            {{ daytour_order.order.customer.notes }}
                        </div>
                    </div>
                </div>
            </div>
        </td>
        <td>
            <div class="p-4 border-l border-gray-light text-gray-dark flex justify-around items-center gap-4">
                <div class="cursor-pointer" @click="daytour_order.isOpened = !daytour_order.isOpened">
                    <font-awesome-icon
                        icon="fa-solid fa-angle-down"
                        class="transition-transform duration-300"
                        :class="daytour_order.isOpened ? '-rotate-135' : ''"
                    />
                </div>
                <round-button
                    v-if="isAdmin && !savingPayment"
                    @click="toggleOrderPayed"
                    :icon="'fa-solid ' + (daytour_order.order.customer.payment_type_id == 1 ? 'fa-money-bill-1-wave' : 'fa-money-bill-wave')"
                    :additional_classes="
                        daytour_order.order.is_payed
                            ? 'border-green-600 bg-green-600'
                            : paymentBorderColors[daytour_order.order.customer.payment_method_id] + ' bg-red-600'
                    "
                />
                <round-button v-if="isAdmin && savingPayment" :icon="'fa-solid fa-spinner'" :additional_classes="'bg-purple border-purple-dark animate-spin'" />

                <round-button v-if="isAdmin" @click="emitter.emit('open-order-modal', orderForModal)" :icon="'fa-solid fa-file-pen'" />
                <round-button
                    v-if="isAdmin && !deleting"
                    :disabled="!daytour_order.isOpened"
                    @click="deleteOrder"
                    :icon="'fa-solid fa-trash-alt'"
                    :additional_classes="'bg-red-600 border-red-600'"
                />
                <round-button v-if="isAdmin && deleting" :icon="'fa-solid fa-spinner'" :additional_classes="'bg-red-600 border-red-600 animate-spin'" />
            </div>
        </td>
    </tr>
    <tr class="bg-gray-light print:hidden" :class="daytour_order.isOpened ? '' : 'hidden'">
        <td colspan="2">
            <div class="flex flex-col mb-4">
                <div class="mx-4 pt-4 flex">
                    <div class="self-stretch basis-12 flex justify-center items-center shrink-0">&nbsp;</div>
                    <div>
                        <font-awesome-icon icon="fa-solid fa-map" class="mr-2" />
                    </div>
                    <div>
                        <template v-for="(ordermeal, index) in daytour_order.order.ordermeals" :key="index">
                            <div v-if="ordermeal.quantity" class="flex gap-2">
                                <div>
                                    {{ ordermeal.quantity }}
                                </div>
                                <div>
                                    <font-awesome-icon icon="fa-solid fa-xmark" class="text-sm" />
                                </div>
                                <div class="shrink">{{ ordermeal.meal.name }}</div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="mx-4 flex" v-if="daytour_order.order.notes">
                    <div class="self-stretch basis-12 flex justify-center items-center shrink-0">&nbsp;</div>
                    <div>
                        <font-awesome-icon icon="fa-solid fa-circle-info" class="mr-2" />
                    </div>
                    <div v-if="daytour_order.order.notes" class="whitespace-pre-line">
                        {{ daytour_order.order.notes }}
                    </div>
                </div>
            </div>
        </td>
    </tr>
    <tr class="hidden print:table-row print:break-inside-avoid text-sm">
        <td class="border border-black" :class="daytour_order.customer_balance > 0 ? 'bg-red-200' : ''">
            <div class="mx-2 text-center">{{ daytour_order.customer_balance }}</div>
        </td>
        <td class="border border-black" :class="paymentBgColors[daytour_order.order.customer.payment_method_id]">
            <div class="mx-2 font-bold flex gap-1">
                {{ daytour_order.order.customer.name }}
                <div v-if="daytour_order.order.customer.phone || daytour_order.order.customer.whatsapp" class="flex gap-1 text-[10px]">
                    <div v-if="daytour_order.order.customer.phone"><font-awesome-icon icon="fa-solid fa-phone" /></div>
                    <div v-if="daytour_order.order.customer.whatsapp"><font-awesome-icon icon="fa-brands fa-whatsapp" /></div>
                </div>
            </div>
        </td>
        <td class="border border-black">
            <div class="mx-2">
                {{ daytour_order.order.customer.address }}
                <div class="flex text-xs" v-if="daytour_order.order.customer.notes || daytour_order.order.notes">
                    <div>
                        <font-awesome-icon icon="fa-solid fa-circle-info" class="mr-2" />
                    </div>
                    <div>
                        <div v-if="daytour_order.order.customer.notes" class="whitespace-pre-line">
                            {{ daytour_order.order.customer.notes }}
                        </div>
                        <div v-if="daytour_order.order.notes" class="whitespace-pre-line">
                            {{ daytour_order.order.notes }}
                        </div>
                    </div>
                </div>
            </div>
        </td>
        <td v-for="(meal, index) in daytour_order.order.ordermeals" :key="index" class="text-center border border-black">
            <div class="mx-2">
                {{ meal.quantity > 0 ? meal.quantity : "" }}
            </div>
        </td>
    </tr>
</template>

<script setup>
import { computed, defineProps, inject, onBeforeMount, toRefs, ref } from "vue";
import { useUserStore } from "@/store/user-store";
import { useToast } from "vue-toast-notification";

import RoundButton from "@/components/global/RoundButton.vue";
import axios from "axios";

const props = defineProps({
    daytour_order: Object,
    sortable: {
        Type: Boolean,
        default: false,
    },
});
const { daytour_order, sortable } = toRefs(props);
const paymentBgColors = {
    1: "bg-white", // bar
    2: "bg-blue-300", // paypal
    3: "bg-orange-400", // überweisung
    4: "bg-yellow-200", // rechnung
    5: "bg-pink-400", // anderes
};
const paymentBorderColors = {
    1: "border-white", // bar
    2: "border-blue-300", // paypal
    3: "border-orange-400", // überweisung
    4: "border-yellow-200", // rechnung
    5: "border-pink-400", // anderes
};

const emitter = inject("emitter");

const userStore = useUserStore();

const $toast = useToast();

const savingPayment = ref(false);
const deleting = ref(false);

let isAdmin = false;
onBeforeMount(() => {
    isAdmin = userStore.$state.role_id === 1;
});

const deleteOrder = async () => {
    if (!daytour_order.value.isOpened) return;

    if (!confirm("Sind Sie sicher, dass sie diese Bestellung löschen möchten? Dies kann nicht rückgängig gemacht werden!")) return;

    deleting.value = true;

    try {
        await axios.delete("order/" + daytour_order.value.order.id);

        $toast.success("Bestellung gelöscht!");
        emitter.emit("order-deleted", daytour_order.value.order);
    } catch (error) {
        let error_string = "";
        if (error.response) {
            if (error.response.data.error) {
                error_string = error.response.data.error;
            } else if (error.response.data.errors) {
                error_string = error.response.data.errors;
                return;
            } else {
                error_string = error;
            }
        } else {
            error_string = error;
        }
        $toast.error("Fehler: " + error_string + "<br>Bestellung nicht gelöscht!");
    }
};

const toggleOrderPayed = async () => {
    let saved_order = {};
    let response = {};
    savingPayment.value = true;

    try {
        response = (await axios.get("order/" + daytour_order.value.order.id + "/toggle_payed")).data;
        saved_order = response.order;
        $toast.success(response.message);
        emitter.emit("order-saved", saved_order);
    } catch (error) {
        let error_string = "";
        if (error.response) {
            if (error.response.data.error) {
                error_string = error.response.data.error;
            } else if (error.response.data.errors) {
                error_string = error.response.data.errors;
                return;
            } else {
                error_string = error;
            }
        } else {
            error_string = error;
        }
        $toast.error("Fehler: " + error_string);
    }
};

const orderForModal = computed({
    get() {
        let order = JSON.parse(JSON.stringify(daytour_order.value.order));
        order.ordermeals = order.ordermeals.filter((ordermeal) => ordermeal.quantity > 0);
        order.options = { fixedcustomer: true, fixeddate: true };
        return order;
    },
});
</script>
