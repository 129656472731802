<template>
    <div class="flex items-center gap-4 flex-wrap m-8 mb-4 justify-around">
        <router-link :to="'/analysis/meals/' + prevDay.format('YYYY-MM-DD')" class="print:hidden text-xl font-bold tracking-widest cursor-pointer mb-4 text-black">
            &lt;
        </router-link>
        <div class="text-xl font-bold tracking-widest cursor-pointer mb-4 text-black text-center basis-1/2">
            <router-link
                :to="'/analysis/meals/' + dayjs().format('YYYY-MM-DD')"
                class="border-b-4 whitespace-nowrap print:border-purple"
                :class="date.isSame(dayjs(), 'day') ? 'border-purple' : 'border-gray'"
            >
                <span v-if="date.isAfter(dayjs(), 'day')" class="print:hidden">&lt;&lt;</span>
                {{ date.locale("de").format("dd DD.MM.YYYY") }}
                <span v-if="date.isBefore(dayjs(), 'day')" class="print:hidden">&gt;&gt;</span>
            </router-link>
        </div>
        <router-link :to="'/analysis/meals/' + nextDay.format('YYYY-MM-DD')" class="print:hidden text-xl font-bold tracking-widest cursor-pointer mb-4 text-black">
            &gt;
        </router-link>
    </div>

    <Skeletor v-if="!menu" height="50vh" />
    <template v-else>
        <table class="w-full">
            <tr class="text-left uppercase text-sm text-white bg-gray-dark">
                <th class="p-4">Gericht</th>
                <th class="p-4">Menge</th>
                <th class="p-4">Preis</th>
                <th class="p-4">Gesamt</th>
            </tr>
            <template v-for="(meal, index) in menu.meals" :key="index">
                <tr :class="index % 2 == 0 ? 'bg-gray-lighter' : 'bg-white'" class="h-10">
                    <td colspan="4">
                        <div class="p-4 pb-0">
                            {{ meal.name }}
                            <font-awesome-icon v-if="meal.is_veggie" class="text-green-600" icon="fa-solid fa-leaf" />
                        </div>
                    </td>
                </tr>
                <tr :class="index % 2 == 0 ? 'bg-gray-lighter' : 'bg-white'" class="h-10">
                    <td>
                        <div class="p-4 pt-0">&nbsp;</div>
                    </td>
                    <td>
                        <div class="p-4 pt-0">
                            {{ meal.count }}
                        </div>
                    </td>
                    <td>
                        <div class="p-4 pt-0">
                            {{ currency.format(meal.price) }}
                        </div>
                    </td>
                    <td>
                        <div class="p-4 pt-0">
                            {{ currency.format(meal.price * meal.count) }}
                        </div>
                    </td>
                </tr>
            </template>
            <tr class="text-white bg-gray-dark">
                <td><div class="p-4">Gesamt</div></td>
                <td>
                    <div class="p-4">{{ total_meal_count }}</div>
                </td>
                <td>&nbsp;</td>
                <td>
                    <div class="p-4">{{ currency.format(total) }}</div>
                </td>
            </tr>
        </table>
        <button
            class="print:hidden font-montserrat text-xs block w-full bg-purple hover:bg-black text-white py-3 transition-colors duration-300 uppercase tracking-widest font-bold"
            type="submit"
            @click="printPage"
        >
            <font-awesome-icon icon="fa-solid fa-print" />
            &nbsp;
            <slot>Drucken</slot>
        </button>
    </template>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useTitleStore } from "@/store/title-store";
import { useRoute } from "vue-router";

import dayjs from "dayjs";
import axios from "axios";
require("dayjs/locale/de");

const route = useRoute();
const titleStore = useTitleStore();
const date = ref(dayjs());
const total = ref(0);
const total_meal_count = ref(0);

const errors = ref([]);
const menu = ref(null);

const currency = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
});

const getMenu = () => {
    errors.value = [];

    axios
        .get("menu_by_date/" + date.value.format("YYYY-MM-DD"))
        .then((response) => {
            menu.value = response.data.menu;

            total.value = 0;
            total_meal_count.value = 0;
            menu.value.meals.forEach((meal) => {
                total.value += meal.count * meal.price;
                total_meal_count.value += parseInt(meal.count);
            });
        })
        .catch((error) => {
            console.log(error);
            if (error.response.status === 404) {
                errors.value["menu"] = error.response.data.message;
                menu.value = [];
            } else {
                console.log(error);
            }
        });
};

onMounted(async () => {
    titleStore.setTitle("Auswertung - Gerichte");

    if (route.params.date) date.value = dayjs(route.params.date);

    getMenu();
});

const prevDay = computed({
    get: () => date.value.subtract(1, "days"),
});
const nextDay = computed({
    get: () => date.value.add(1, "days"),
});

const printPage = () => {
    window.print();
};
</script>
