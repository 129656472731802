<template>
    <div class="print:hidden">
        <table v-if="_daytour_orders && _daytour_orders.done && _daytour_orders.cancelled" class="w-full">
            <tbody v-for="(daytour_order, index) in [..._daytour_orders.done, ..._daytour_orders.cancelled]" :key="index">
                <admin-daytour-order-row :daytour_order="daytour_order" />
            </tbody>
        </table>
        <draggable
            v-if="_daytour_orders"
            class="list-group w-full"
            tag="table"
            :component-data="{
                type: 'transition-group',
            }"
            v-model="_daytour_orders.open"
            item-key="order"
            handle=".handle"
            :animation="250"
            @end="orderChanged"
        >
            <template #item="{ element }">
                <tbody>
                    <admin-daytour-order-row :sortable="!isBeforeToday" :daytour_order="element" />
                </tbody>
            </template>
        </draggable>
        <button
            v-if="custom_sort_order"
            class="font-montserrat text-xs block w-full bg-purple hover:bg-black text-white rounded-sm py-3 transition-colors duration-300 uppercase tracking-widest font-bold"
            type="submit"
            @click="resetSortOrder"
        >
            <font-awesome-icon icon="fa-solid fa-user-edit" />
            &nbsp;Sortierung auf Standard zurücksetzen
        </button>
    </div>
    <table class="mx-auto hidden print:table text-left border-collapse border">
        <tr>
            <th class="border border-black"><div class="mx-2">Bezahlt</div></th>
            <th class="border border-black"><div class="mx-2">Name</div></th>
            <th class="border border-black"><div class="mx-2">Anschrift</div></th>
            <th v-for="(meal, index) in meals" :key="index" class="border border-black">
                <div class="mx-2 text-center leading-none">
                    Menü&nbsp;{{ index + 1 }}
                    <br />
                    <div class="text-xs font-thin">{{ meal.name }}</div>
                </div>
            </th>
        </tr>
        <template v-if="_daytour_orders">
            <template v-for="(daytour_order, index) in _daytour_orders.open" :key="index">
                <admin-daytour-order-row :daytour_order="daytour_order" />
            </template>
            <tr class="font-bold">
                <td class="border border-black">
                    <div class="mx-2 text-center">{{ balanceSum }}</div>
                </td>
                <td class="border border-black"></td>
                <td class="border border-black"></td>
                <td v-for="(meal, index) in meals" :key="index" class="border border-black">
                    <div class="mx-2 text-center">{{ meal.count }}</div>
                </td>
            </tr>
        </template>
    </table>
</template>

<script setup>
import axios from "axios";
import { computed, defineProps, inject, onMounted, ref, toRefs } from "vue";
import draggable from "vuedraggable";

import AdminDaytourOrderRow from "@/components/partials/daytour/AdminDaytourOrderRow.vue";

const _ = require("lodash");

const emitter = inject("emitter");

const props = defineProps({ daytour_orders: Object, meals: Object, isBeforeToday: Boolean });

const { daytour_orders, meals, isBeforeToday } = toRefs(props);

const _daytour_orders = ref(null);

const custom_sort_order = ref(false);

const balanceSum = computed({
    get() {
        if (!_daytour_orders.value) return 0;

        let sum = 0;
        _daytour_orders.value.open.forEach((daytour_order) => {
            sum += daytour_order.customer_balance;
        });

        return sum;
    },
});

onMounted(() => {
    _daytour_orders.value = daytour_orders.value;

    daytour_orders.value.open.forEach((daytour_order) => {
        if (daytour_order.sort_order) custom_sort_order.value = true;

        meals.value.forEach((meal) => {
            if (!daytour_order.order.ordermeals.find((ordermeal) => ordermeal.meal_id === meal.id)) {
                daytour_order.order.ordermeals.push({
                    meal_id: meal.id,
                    quantity: 0,
                    meal: meal,
                });
            }
        });
        daytour_order.order.ordermeals = _.sortBy(daytour_order.order.ordermeals, "meal_id");
    });
});

const orderChanged = () => {
    const sort_order = _daytour_orders.value.open.map((daytour_order) => {
        return daytour_order.id;
    });

    axios.post("daytour/sortorder?_method=PUT", { new_order: sort_order });

    custom_sort_order.value = true;
};

const resetSortOrder = async () => {
    const reset_ids = _daytour_orders.value.open.map((daytour_order) => {
        return daytour_order.id;
    });

    await axios.post("daytour/reset_sortorder?_method=PUT", { new_order: reset_ids });

    document.location.reload();
};

emitter.on("order-saved", (order) => {
    const index = _daytour_orders.value.open.findIndex((o) => o.id === order.id);

    if (index !== -1) {
        meals.value.forEach((meal) => {
            if (!order.ordermeals.find((ordermeal) => ordermeal.meal_id === meal.id)) {
                order.ordermeals.push({
                    meal_id: meal.id,
                    quantity: 0,
                    meal: meal,
                });
            }
        });
        order.ordermeals = _.sortBy(order.ordermeals, "meal_id");

        if (_daytour_orders.value.open[index].order.is_payed && !order.is_payed) {
            _daytour_orders.value.open[index].customer_balance += order.ordermeals.reduce((total_price, ordermeal) => {
                return total_price + (ordermeal.quantity > 0 ? ordermeal.quantity * ordermeal.meal.price : 0);
            }, 0);
        } else if (!_daytour_orders.value.open[index].order.is_payed && order.is_payed) {
            _daytour_orders.value.open[index].customer_balance -= order.ordermeals.reduce((total_price, ordermeal) => {
                return total_price + (ordermeal.quantity > 0 ? ordermeal.quantity * ordermeal.meal.price : 0);
            }, 0);
        }

        _daytour_orders.value.open[index].order = order;
    }
});

emitter.on("order-deleted", (order) => {
    const index = _daytour_orders.value.open.findIndex((o) => o.id === order.id);

    if (index !== -1) {
        _daytour_orders.value.open.splice(index, 1);
    }
});
</script>
<style>
.fade-move,
.fade-enter-active,
.fade-leave-active {
    @apply transition-all duration-1000;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. declare enter from and leave to state */
.fade-leave-to {
    @apply opacity-0 translate-x-full;
}
.fade-enter-from {
    @apply opacity-0 translate-y-full;
}

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
.fade-leave-active {
    @apply absolute;
}
</style>
