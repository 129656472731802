<template>
    <div class="flex items-center gap-4 flex-wrap m-8">
        <Skeletor v-if="!data" />
        <template v-else>
            <div
                v-for="(date, index) in data"
                :key="index"
                class="text-xl font-bold tracking-widest cursor-pointer"
                :id="'kw-tab-' + date.year + '-' + date.week"
            >
                KW{{ date.week }}
            </div>
        </template>
    </div>
    <Skeletor v-if="!data" height="60vh" />
    <template v-else>
        <div v-for="(date, index) in data" :key="index" :id="'kw-content-' + date.year + '-' + date.week">
            <weekmenu-table :weekdata="date" />
        </div>
    </template>
</template>

<script setup>
import { ref, nextTick, onMounted } from "vue";
import { Tabs } from "flowbite";
import { useTitleStore } from "@/store/title-store";

import WeekmenuTable from "@/components/partials/weekmenu/WeekmenuTable.vue";

import dayjs from "dayjs";
import axios from "axios";

var isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);
const titleStore = useTitleStore();
titleStore.setTitle("Wochenmenü");

onMounted(() => {
    const today = dayjs();
    let tabElements = [];

    axios.get("menues_by_week/" + today.subtract(7, "days").year() + "/" + today.subtract(7, "days").isoWeek() + "/3").then((response) => {
        data.value = response.data;

        nextTick(() => {
            data.value.forEach((date) => {
                tabElements.push({
                    id: date.year + "-" + date.week,
                    triggerEl: document.querySelector("#kw-tab-" + date.year + "-" + date.week),
                    targetEl: document.querySelector("#kw-content-" + date.year + "-" + date.week),
                });
            });

            const options = {
                activeClasses: "text-black border-b-4 border-purple",
                inactiveClasses: "text-gray-light",
                defaultTabId: today.year() + "-" + today.isoWeek()
            };
            new Tabs(tabElements, options);
        });
    });
});

const data = ref(null);
</script>
