<template>
    <template v-if="orders">
        <table class="w-full mb-4" v-for="(orders, weekNumber) in sortedOrders" :key="weekNumber">
            <tr class="text-left uppercase text-sm text-white bg-gray-dark cursor-pointer max-w-full" @click="opened[weekNumber] = !opened[weekNumber]">
                <th class="p-2 sm:p-4">KW {{ weekNumber }}</th>
                <th class="p-2 sm:p-4 text-center"><template v-if="opened[weekNumber]">Menü</template></th>
                <th class="p-2 sm:p-4 text-center"><template v-if="opened[weekNumber]">Bezahlt</template></th>
                <th class="p-2 sm:p-4 text-right"><template v-if="opened[weekNumber]">Summe</template></th>
                <th class="p-2 sm:p-4 text-right">
                    <font-awesome-icon
                        icon="fa-solid fa-angle-down"
                        class="transition-transform duration-300"
                        :class="opened[weekNumber] ? '-rotate-135' : ''"
                    />
                </th>
            </tr>
            <template v-if="opened[weekNumber]">
                <tbody
                    v-for="(order, index) in orders"
                    :key="index"
                    :class="
                        dayjs(order.date).startOf('day').isSame(dayjs().startOf('day'))
                            ? 'font-bold'
                            : dayjs(order.date).isBefore(dayjs())
                            ? 'text-gray-light'
                            : ''
                    "
                >
                    <tr :class="index % 2 == 0 ? 'bg-gray-lighter' : 'bg-white'" class="h-20">
                        <td>
                            <div class="mx-1 sm:mx-4">{{ dayjs(order.date).locale("de").format("ddd DD.MM.") }}</div>
                        </td>
                        <td class="w-full max-w-0">
                            <div class="mx-1 sm:mx-4 flex flex-col">
                                <div v-for="(ordermeal, index2) in order.ordermeals" :key="index2" class="flex gap-2">
                                    <div class="shrink-0">
                                        {{ ordermeal.quantity }}
                                        <font-awesome-icon icon="fa-solid fa-xmark" class="text-sm" />
                                    </div>
                                    <div class="overflow-x-auto whitespace-nowrap">{{ ordermeal.meal.name }}</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="mx-1 sm:mx-4 flex flex-col text-center">
                                <checkbox-input label="" v-model:input="order.is_payed" @change="setOrderPayed(order)" />
                            </div>
                        </td>
                        <td>
                            <div class="mx-1 sm:mx-4 flex flex-col text-right">
                                <div v-for="(ordermeal, index2) in order.ordermeals" :key="index2">
                                    {{ currency.format(ordermeal.quantity * ordermeal.meal.price) }}
                                </div>
                            </div>
                        </td>
                        <td>&nbsp;</td>
                    </tr>
                </tbody>
                <tr class="text-white bg-gray-dark">
                    <td class="p-2 sm:p-4">Summe</td>
                    <td class="p-2 sm:p-4">&nbsp;</td>
                    <td class="p-2 sm:p-4">{{ currency.format(weekPayedTotal(weekNumber)) }}</td>
                    <td class="p-2 sm:p-4 text-right">{{ currency.format(weekTotal(weekNumber)) }}</td>
                    <td class="p-2 sm:p-4">&nbsp;</td>
                </tr>
            </template>
        </table>
    </template>
</template>

<script setup>
import { computed, defineProps, inject, ref, toRefs } from "vue";
import { useToast } from "vue-toast-notification";
import dayjs from "dayjs";
require("dayjs/locale/de");
const isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);

import CheckboxInput from "@/components/global/CheckboxInput.vue";
import axios from "axios";

const props = defineProps({ orders: Object });
const { orders } = toRefs(props);

const opened = ref([]);

const $toast = useToast();
const emitter = inject("emitter");

const currency = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
});

const sortedOrders = computed({
    get() {
        let ordersByWeek = orders.value.reduce((acc, order) => {
            const week = dayjs(order.date).isoWeek();
            acc[week] = [...(acc[week] || []), order];
            return acc;
        }, {});

        for (const week in ordersByWeek) {
            ordersByWeek[week].sort((a, b) => {
                return dayjs(a.date).isBefore(b.date) ? -1 : 1;
            });
        }

        return ordersByWeek;
    },
});

const weekTotal = (weekNumber) => {
    let total = 0;
    for (const order of sortedOrders.value[weekNumber]) {
        for (const meal of order.ordermeals) {
            total += meal.quantity * meal.meal.price;
        }
    }
    return total;
};

const weekPayedTotal = (weekNumber) => {
    let total = 0;
    for (const order of sortedOrders.value[weekNumber]) {
        for (const meal of order.ordermeals) {
            if (order.is_payed) total += meal.quantity * meal.meal.price;
        }
    }
    return total;
};

const setOrderPayed = async (order) => {
    let saved_order = {};

    try {
        order.order_id = order.id;
        order.ordermeals_to_delete = [];
        saved_order = (await axios.post("order/" + order.id + "?_method=PUT", order)).data.order;
        $toast.success("Zahlung " + (order.is_payed ? "gespeichert" : "gelöscht") + "!");
        emitter.emit("order-saved", saved_order);
    } catch (error) {
        let error_string = "";
        if (error.response.data.error) {
            error_string = error.response.data.error;
        } else if (error.response.data.errors) {
            error_string = error.response.data.errors;
            return;
        } else {
            error_string = error;
        }
        $toast.error("Fehler: " + error_string + "<br>Zahlung nicht " + (order.is_payed ? "gespeichert" : "gelöscht") + "!");
    }
};
</script>
