<template>
    <div>
        <h2 class="w-full max-w-4xl mx-auto px-8 text-center md:text-left text-xl font-bold pt-5 uppercase text-gray-dark">Kunden</h2>
        <searchable-customer-list :showUnfiltered="false" />
    </div>
    <div class="border-t-4 border-gray-light">
        <h2 class="w-full max-w-4xl mx-auto px-8 text-center md:text-left text-xl font-bold py-5 uppercase text-gray-dark">Wochenmenü</h2>

        <Skeletor v-if="!data" height="60vh" />
        <template v-else>
            <div v-for="(date, index) in data" :key="index" :id="'kw-content-' + date.year + '-' + date.week">
                <weekmenu-table :weekdata="date" :today_open="true" />
            </div>
        </template>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useTitleStore } from "@/store/title-store";

import SearchableCustomerList from "@/components/partials/customers/SearchableCustomerList.vue";
import WeekmenuTable from "@/components/partials/weekmenu/WeekmenuTable.vue";

import dayjs from "dayjs";
import axios from "axios";

var isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);

const titleStore = useTitleStore();
const data = ref(null);

onMounted(() => {
    titleStore.setTitle("Übersicht");

    const today = dayjs();

    axios.get("menues_by_week/" + today.year() + "/" + today.isoWeek()).then((response) => {
        response.data[0].days.filter((day) => {
            if (day.date === today.format("YYYY-MM-DD")) {
                day.isOpened = true;
            }
        });
        data.value = response.data;
    });
});
</script>
