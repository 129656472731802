import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import "./axios.js";

import router from "./router";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faAngleDown,
    faAngleUp,
    faCheck,
    faCircleInfo,
    faFileCirclePlus,
    faFilePen,
    faGrip,
    faInfo,
    faLeaf,
    faLock,
    faMap,
    faMapMarker,
    faMapMarkerAlt,
    faMoneyBillWave,
    faMoneyBill1Wave,
    faPencilAlt,
    faPhone,
    faPlus,
    faPrint,
    faSearch,
    faSpinner,
    faTrashAlt,
    faUser,
    faUserEdit,
    faUserPlus,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { faShareSquare } from "@fortawesome/free-regular-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import "vue-toast-notification/dist/theme-sugar.css";
import "./assets/css/vue-skeletor.css";
import "./assets/css/vue-toast-theme.css";
import "./assets/css/burger-menu.css";
import "./assets/css/style.css";

library.add(
    faAngleDown,
    faAngleUp,
    faCheck,
    faCircleInfo,
    faFileCirclePlus,
    faFilePen,
    faGrip,
    faInfo,
    faLeaf,
    faLock,
    faMap,
    faMapMarker,
    faMapMarkerAlt,
    faMoneyBillWave,
    faMoneyBill1Wave,
    faPencilAlt,
    faPhone,
    faPlus,
    faPrint,
    faSearch,
    faSpinner,
    faShareSquare,
    faTrashAlt,
    faUser,
    faUserEdit,
    faUserPlus,
    faWhatsapp,
    faXmark
);

import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import { Skeletor } from "vue-skeletor";

import "./registerServiceWorker";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

router.beforeEach((to, from, next) => {
    pinia.state.value.title.title = "";
    next();
});

import mitt from "mitt"; // Import mitt
const emitter = mitt(); // Initialize mitt

let app = createApp(App);
app.use(router);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component(Skeletor.name, Skeletor);
app.use(pinia);
app.provide("emitter", emitter);
app.mount("#app");
