import { createRouter, createWebHistory } from "vue-router";
import AnalysisMealsView from "../views/analysis/AnalysisMealsView.vue";
import CustomerDetailsView from "../views/customers/CustomerDetailsView.vue";
import CustomersTourView from "../views/customers/CustomersTourView.vue";
import CustomersView from "../views/customers/CustomersView.vue";
import DashboardView from "../views/DashboardView.vue";
import DaytourView from "../views/DaytourView.vue";
import LoginView from "../views/LoginView.vue";
import LogoutView from "../views/LogoutView.vue";
import OrdersView from "../views/OrdersView.vue";
import WeekmenuView from "../views/WeekmenuView.vue";

const user = JSON.parse(window.localStorage.getItem("user"));

const requiresAuth = (to, from, next) => {
    if (!user || !user.token) {
        next({
            name: "login",
        });
    } else {
        next();
    }
};

const routes = [
    {
        path: "/",
        redirect: "login",
    },
    {
        path: "/login",
        name: "login",
        component: LoginView,
        beforeEnter(to, from, next) {
            if (user && user.token) {
                next("/dashboard");
            } else {
                next();
            }
        },
    },
    {
        path: "/dashboard",
        name: "Übersicht",
        component: DashboardView,
        beforeEnter: requiresAuth,
    },
    {
        path: "/customers",
        name: "kundendaten",
        component: CustomersView,
        beforeEnter: requiresAuth,
    },
    {
        path: "/customer/:id",
        name: "kundendetails",
        component: CustomerDetailsView,
        beforeEnter: requiresAuth,
    },
    {
        path: "/weekmenu",
        name: "wochenmenü",
        component: WeekmenuView,
        beforeEnter: requiresAuth,
    },
    {
        path: "/orders",
        name: "bestellungen",
        component: OrdersView,
        beforeEnter: requiresAuth,
    },
    {
        path: "/daytour/:tour/:date?",
        name: "daytour",
        component: DaytourView,
        beforeEnter: requiresAuth,
    },
    {
        path: "/customers/tour/:tour",
        name: "tour",
        component: CustomersTourView,
        beforeEnter: requiresAuth,
    },
    {
        path: "/logout",
        name: "Logout",
        component: LogoutView,
        beforeEnter: requiresAuth,
    },
    {
        path: "/analysis/meals/:date?",
        component: AnalysisMealsView,
        beforeEnter: requiresAuth,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
