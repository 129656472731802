<template>
    <table class="w-full">
        <template v-for="(day, index) in makeWeekArray(weekdata.days[0])" :key="index">
            <tr :class="index % 2 == 0 ? 'bg-gray-lighter' : 'bg-white'" class="h-20">
                <td>
                    <div class="p-4">{{ day }}</div>
                </td>
                <td>
                    <div
                        class="p-4 border-gray-light text-gray-dark flex justify-around items-center"
                        :class="isAdmin || (weekdata.days[index].meals && weekdata.days[index].meals.length > 0) ? 'border-l' : ''"
                    >
                        <div
                            class="cursor-pointer"
                            v-if="weekdata.days[index].meals && weekdata.days[index].meals.length > 0"
                            @click="weekdata.days[index].isOpened = !weekdata.days[index].isOpened"
                        >
                            <font-awesome-icon
                                icon="fa-solid fa-angle-down"
                                class="transition-transform duration-300"
                                :class="weekdata.days[index].isOpened ? '-rotate-135' : ''"
                            />
                        </div>
                        <round-button
                            v-if="isAdmin"
                            @click="
                                (weekdata.days[index].isOpened || weekdata.days[index].meals || weekdata.days[index].meals.length === 0) &&
                                    emitter.emit('open-menu-modal', weekdata.days[index])
                            "
                            :icon="'fa-solid ' + (!weekdata.days[index].meals || weekdata.days[index].meals.length === 0 ? 'fa-plus' : 'fa-pencil-alt')"
                            :disabled="weekdata.days[index].meals && weekdata.days[index].meals.length > 0 && !weekdata.days[index].isOpened"
                        />
                    </div>
                </td>
            </tr>
            <tr :class="weekdata.days[index].isOpened && weekdata.days[index].meals && weekdata.days[index].meals.length ? '' : 'hidden'">
                <td colspan="2">
                    <div class="bg-gray-light p-2">
                        <div v-for="(meal, mealIndex) in weekdata.days[index].meals" :key="mealIndex" class="flex gap-4 px-4 py-2">
                            <div>Gericht&nbsp;{{ mealIndex + 1 }}</div>
                            <div>
                                {{ meal.name }}
                                <font-awesome-icon v-if="meal.is_veggie" class="text-green-600" icon="fa-solid fa-leaf" />
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </template>
    </table>
</template>

<script setup>
import { defineProps, inject, onBeforeMount, toRefs } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useUserStore } from "@/store/user-store";
import dayjs from "dayjs";

import RoundButton from "@/components/global/RoundButton.vue";

require("dayjs/locale/de");
const isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);

const emitter = inject("emitter");

const props = defineProps({
    weekdata: Object,
});

const { weekdata } = toRefs(props);

const userStore = useUserStore();

let new_menu = [[]];
new_menu.isOpened = true;

function makeWeekArray(startday) {
    return new Array(6).fill(dayjs(startday.date)).map((day, idx) => day.add(idx, "day").locale("de").format("dd. DD.MM.YYYY"));
}

emitter.on("menu-saved", (menu) => {
    const index = weekdata.value.days.findIndex((day) => day.id === menu.id);

    if (index !== -1) {
        weekdata.value.days[index] = menu;
    } else {
        weekdata.value.days.push(menu);
    }
});

let isAdmin = false;
onBeforeMount(() => {
    isAdmin = userStore.$state.role_id === 1;
});
</script>
