<template>
    <div
        class="bg-white font-roboto-condensed text-gray print:text-black min-h-screen flex flex-col justify-between text-lg select-none selection:bg-purple selection:text-white"
    >
        <Header class="print:hidden" />
        <div class="w-full max-w-4xl mx-auto mb-20 grow print:max-w-none">
            <slot />
        </div>

        <modal-handler v-if="isLoggedIn" />

        <Footer class="print:hidden" />
    </div>
</template>

<script setup>
import { useUserStore } from "@/store/user-store";

import Header from "./DefaultHeaderLayout.vue";
import ModalHandler from "@/components/global/ModalHandler.vue";
import Footer from "./DefaultFooterLayout.vue";

const userStore = useUserStore();
const isLoggedIn = !!userStore.token;
</script>
