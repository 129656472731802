<template>
    <table class="w-full">
        <tr class="text-left uppercase text-sm text-white bg-gray-dark">
            <th class="p-4">Name</th>
            <th class="p-4">Aktionen</th>
        </tr>
        <template v-for="(order, index) in orders" :key="index">
            <tr :class="index % 2 == 0 ? 'bg-gray-lighter' : 'bg-white'" class="h-20">
                <td>
                    <div class="p-4">{{ order.customer.name }}</div>
                </td>
                <td>
                    <div class="p-4 border-l border-gray-light text-gray-dark flex justify-around items-center gap-4">
                        <div class="cursor-pointer" @click="order.isOpened = !order.isOpened">
                            <font-awesome-icon
                                icon="fa-solid fa-angle-down"
                                class="transition-transform duration-300"
                                :class="order.isOpened ? '-rotate-135' : ''"
                            />
                        </div>
                        <round-button
                            @click="order.isOpened ? emitter.emit('open-order-modal', order) : void 0"
                            :icon="'fa-solid fa-pencil-alt'"
                            :disabled="!order.isOpened"
                        />
                    </div>
                </td>
            </tr>
            <tr v-if="order.notes && false" :class="index % 2 == 0 ? 'bg-gray-lighter' : 'bg-white'">
                <td colspan="4">
                    <div class="text-sm mx-4 text-right whitespace-pre-line">{{ order.notes }}</div>
                </td>
            </tr>
            <tr :id="'order-details-' + index" class="bg-gray-light" :class="order.isOpened ? '' : 'hidden'">
                <td colspan="4">
                    <div class="flex flex-col">
                        <div class="mx-4 py-4 flex">
                            <div>
                                <font-awesome-icon icon="fa-solid fa-map-marker" class="mr-2" />
                            </div>
                            <div>
                                <div>{{ order.customer.name }}</div>
                                <div>{{ order.customer.address }}</div>
                                <div>({{ order.customer.tour.name }})</div>

                                <div v-if="order.customer.notes" class="text-sm whitespace-pre-line">({{ order.customer.notes }})</div>
                            </div>
                        </div>
                        <div class="mx-4 py-4 border-t border-gray flex">
                            <div>
                                <font-awesome-icon icon="fa-solid fa-map" class="mr-2" />
                            </div>
                            <div>
                                <div v-for="(ordermeal, index) in order.ordermeals" :key="index" class="flex gap-2">
                                    <div>{{ ordermeal.quantity }}x</div>
                                    <div>
                                        {{ ordermeal.meal.name }}
                                        <font-awesome-icon v-if="ordermeal.meal.is_veggie" class="text-green-600" icon="fa-solid fa-leaf" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mx-4 py-4 border-gray flex" v-if="order.notes">
                            <div>
                                <font-awesome-icon icon="fa-solid fa-circle-info" class="mr-2" />
                            </div>
                            <div v-if="order.notes" class="whitespace-pre-line">
                                {{ order.notes }}
                            </div>
                        </div>

                        <div class="mx-4 py-4">
                            <button
                                class="font-montserrat text-xs block w-full bg-purple hover:bg-black text-white py-3 transition-colors duration-300 uppercase tracking-widest font-bold"
                                type="submit"
                            >
                                <font-awesome-icon icon="fa-solid fa-xmark" />
                                &nbsp;Bestellung löschen
                            </button>
                        </div>
                    </div>
                </td>
            </tr>
        </template>
    </table>
</template>

<script setup>
import { defineProps, inject, toRefs } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import RoundButton from "@/components/global/RoundButton.vue";

const emitter = inject("emitter");

const props = defineProps({
    orders: Object,
});

const { orders } = toRefs(props);
</script>
