import axios from "axios";
import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
    state: () => ({
        id: null,
        token: null,
        first_name: null,
        last_name: null,
        username: null,
        role_id: null,
    }),

    actions: {
        async setUserDetails(res) {
            (this.$state.id = res.data.user.id),
                (this.$state.token = res.data.token),
                (this.$state.first_name = res.data.user.first_name),
                (this.$state.last_name = res.data.user.last_name),
                (this.$state.username = res.data.user.username),
                (this.$state.role_id = res.data.user.role_id);
        },

        async fetchUser() {
            let res = await axios.get("user/" + this.$state.id);
            this.setUserDetails(res);
        },

        clearUser() {
            (this.$state.id = null),
                (this.$state.token = null),
                (this.$state.first_name = null),
                (this.$state.last_name = null),
                (this.$state.username = null),
                (this.$state.role_id = null);
        },
    },
    persist: true,
});
